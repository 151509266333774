import React, { useState } from "react";
import cx from "classnames";
import { useInView } from "react-intersection-observer";

import * as styles from "./reveal.module.css";

export const Reveal = ({
	style,
	children,
}: {
	style?: "borderBottom";
	children: React.ReactNode;
}) => {
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.5,
	});

	return (
		<div
			className={cx(
				styles.reveal,
				style ? styles[style] : "",
				inView ? cx("in-view", styles.inView) : ""
			)}
			ref={ref}
		>
			{children}
		</div>
	);
};
