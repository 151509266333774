import React from "react";
import { getInternalLinkPath } from "src/utils/getInternalLinkPath";
import { Signoff } from "src/components/homepage/mission-statement";

export const Serializer = {
	types: {
		missionStatementSignoff: (props: any) => {
			return (
				<Signoff
					developerCredit={props.node.developerCredit}
					signoff={props.node.signoff}
				/>
			);
		},
	},
	marks: {
		link: ({ mark, children }) => {
			const { blank, href } = mark;
			return blank ? (
				<a href={href} target="_blank" rel="noopener">
					{children}
				</a>
			) : (
				<a href={href}>{children}</a>
			);
		},
		internalLink: ({ mark, children }) => {
			const { reference } = mark.reference;
			const path = getInternalLinkPath(reference);
			return <Link to={path}>{children}</Link>;
		},
	},
};
