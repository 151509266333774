import React, { useState } from "react";
import cx from "classnames";
import { Collapse } from "@material-ui/core";
import { BlockCopy } from "src/components/global/block-copy";
import { Reveal } from "src/components/global/reveal";
import { ReactComponent as CaretDown } from "../../../images/svg/caret-down.svg";
import { ReactComponent as FieldsGradeVerticalLogo } from "../../../images/svg/fields-grade-vertical-logo.svg";

import styles from "./mission-statement.module.css";

interface MissionStatementProps {
	data: {
		rotatingPrompts: [
			{
				_key: string;
			}
		];
		pullquote: string;
		paragraphCopy: [];
	};
}
export const MissionStatement = ({ data }: MissionStatementProps) => {
	const [expanded, setExpanded] = useState(false);
	const { rotatingPrompts, pullquote, paragraphCopy } = data;

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<Reveal style="borderBottom">
			<div className={styles.missionStatement}>
				<button
					className={cx(styles.promptsButton)}
					style={
						{
							"--animation-duration": `${rotatingPrompts.length * 2.5}s`,
						} as React.CSSProperties
					}
					onClick={handleExpandClick}
				>
					<div className={styles.caretContainer}>
						<CaretDown
							className={cx(styles.caretDown, expanded ? styles.flip : "")}
						/>
					</div>
					<div className={styles.prompts}>
						{rotatingPrompts &&
							rotatingPrompts.map((prompt, i) => (
								<span
									className={cx("sans sans--caps-11 medium", styles.prompt)}
									key={`prompt_${i}`}
								>
									{prompt}
								</span>
							))}
					</div>
				</button>
				<Collapse in={expanded} timeout="auto" unmountOnExit>
					<div className={styles.content}>
						<div className={styles.contentInner}>
							<blockquote
								className={cx("serif serif--26 lower", styles.pullquote)}
							>
								{pullquote}
							</blockquote>
							<BlockCopy
								className={cx("sans sans--body-16", styles.blockCopy)}
								content={paragraphCopy}
							/>
							<FieldsGradeVerticalLogo className={styles.logo} />
						</div>
					</div>
				</Collapse>
			</div>
		</Reveal>
	);
};
