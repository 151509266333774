import React from "react";
import cx from "classnames";
import BlockContent from "@sanity/block-content-to-react";
import { Serializer } from "src/utils/serializer";

import styles from "./block-copy.module.css";

interface BlockCopyProps {
	content: [] | undefined;
	className?: string;
}

export const BlockCopy = (props: BlockCopyProps) => {
	const { content, className } = props;

	if (!content) {
		console.error("Missing content!");
		return null;
	}

	return (
		<div className={cx(styles.blockCopy, className)}>
			<BlockContent blocks={content} serializers={Serializer} />
		</div>
	);
};
