import React from "react";
import cx from "classnames";

import styles from "./mission-statement.module.css";

interface SignoffProps {
	developerCredit: string;
	signoff: string;
}

export const Signoff = ({ developerCredit, signoff }: SignoffProps) => {
	return (
		<div className={styles.signoff}>
			<h4 className={cx("serif serif--26 lower", styles.signoffCopy)}>
				{signoff}
			</h4>
			<span className={cx("sans sans--caps-11", styles.signoffCredit)}>
				{developerCredit}
			</span>
		</div>
	);
};
